.backdrop {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: #00000094;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    backdrop-filter: blur(5px);
}
.main-content {
    overflow: scroll;
    min-width: 500px;
    max-width: 500px;
    background-color:  white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

}

.main-content > .heading {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #30ac30;
    font-weight: 600;
    font-size: 12px;
}


.notification-write-up{
    font-weight: 800; 
    cursor: pointer;
    font-size: 15px;

}

.accordion{
    color: #48a026;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;

}

.active, .accordion:hover {
    background-color: #ccc; 
  }
  

.panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
}

.head-section{


    display: flex;
    
    justify-content: space-between;

}

.notification-body > p{
    font-size: 12px;
}