
* {
    margin: 0;
    padding: 0;
}
.userActivity{
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: #fafafa;
    border-bottom:  1px solid #f0f0f0;
    line-height: 0px;
    margin: 20px;
    padding: 10px;

}

.userActivity time {
    font-weight: 200;
    font-style: italic;
    color: #3c3c3c;
}

.userActivity h6 span[aria-status="FAILURE"]{
    color: red;
}
.userActivity h6 span[aria-status="SUCCESS"]{
    color:#41b883 ;
}