.widget-container{

    display: flex;
    justify-content: space-between;
}

.cashout-button-filter{

    display: flex ;
    gap: 10px;
    align-items: center;
    background-color: #36bc74; 
    border-radius: 100px;
    padding: 8px;
}

button{
    padding: 10px 12px;
    border-radius: 100px !important;
    min-width: 100px;
    border: none;
    
}