.backdrop {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: #00000094;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    backdrop-filter: blur(5px);
}
.main-content {
    overflow: scroll;
    min-width: 500px;
    max-width: 500px;
    background-color:  white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    padding-top: 30px;

}

.main-content > .heading {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #30ac30;
    font-weight: 600;
    font-size: 12px;
}



.main-content button  {
    padding: 10px;
    background-color: #30ac30;
    border: none;
    color: white;
}

.action-controls {

    display: flex;
    gap: 10px;
    align-items: center;
}
