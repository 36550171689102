.sell_container {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
.sell_container_tabs {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  border: solid 1px #1f3226;
  padding: 1em;
  border-radius: 13px;
  background: #0b110c;
}
.sell_container_tabs_1_active {
  background: #39915a;
  color: #fff;
  padding: 15px;
  border-radius: 9px;
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1em;
}
.sell_container_body_cont1_title_div_btn {
  width: 168px;
  padding: 15px 0px;
  margin-left: 10px;
  border-radius: 9px;
  border: none;
  background: #39915a;
  /* color: #eee; */
}
.uploadDiv {
  padding: 2em;
  /* background: #17171780; */
  border-radius: 13px;
  border: solid 1px #425a4b;
  backdrop-filter: blur(9px);
}
/* ================ */
/* ================ */
/* ================ */
/* ================ */
.sell_container_prod_spec_input_div_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}
.sell_container_prod_spec_input_divs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
  /* color: #fff; */
  font-size: 36px;
}

.sell_container_prod_spec_input_div1 {
  width: 100%;
  margin: 0px 6px;
  padding: 15px;
  background: #0b110c;
  border: solid 1px #425a4b;
  border-radius: 11px;
  color: #fff;
  font-size: 16px;
}
.sell_container_prod_spec_input_div_btn {
  width: 100%;
  background: #123f23;
  padding: 15px;
  border-radius: 11px;
  /* max-width: 227px; */
  color: #fff;
  border: none;
  font-weight: 500;
}
.sell_container_prod_spec_input_div_area_title_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #7b9a89;
  margin-bottom: 10px;
}
.sell_container_prod_spec_input_div_area_title_area1 {
  padding: 0px 10px;
}
/* ================ */
/* ================ */
/* ================ */
/* ================ */
/* ================ */
.addCategoryDiv_sales {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #0000006b;
  left: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.demo-wrapper {
  width: 100% !important;
}
.sell_container_tabs_1 {
  background: #141f18;
  color: #799985;
  padding: 15px;
  border-radius: 9px;
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1em;
}
.sell_container_header {
  font-size: 42px;
  color: #fff;
  font-weight: 600;
}
.sell_container_body_cont1_submit_btn:disabled {
  background: #425a4b;
  cursor: not-allowed;
  color: #83a992;
}
.sell_container_body {
  display: flex;
  flex-direction: column;
  margin-top: 0em;
}
.sell_container_body_cont1 {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
.sell_container_body_cont1_title_div {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.sell_container_body_cont1_title_div_input {
  width: 100%;
  padding: 1em;
  border-radius: 12px;
  background: none;
  border: solid 2px #425a4b;
  color: #abc7b6;
}
.sell_container_body_cont1_txt {
  display: flex;
  flex-direction: column;
  color: #83a28f;
  font-size: 13px;
}
.sell_container_body_cont1_txt_heading {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.sell_container_body_cont1_img_display_cont {
  width: 100%;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sell_container_body_cont1_img_display_cont_1 {
  width: 100%;
  margin-right: 1em;
}
.sell_container_body_cont1_img_display_cont_divs {
  border: solid 3px #abc7b6;
  border-style: dashed;
  height: 20em;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sell_container_body_cont1_img_display_cont_div1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #94af9f;
  background: #101d15;
  cursor: pointer;
  transition: 0.1s linear;
}
.sell_container_body_cont1_img_display_cont_div1_icon {
  font-size: 100px !important;
}
.sell_container_body_cont1_img_display_cont_div1:hover {
  transition: 0.1s linear;
  background: #000;
}
.sell_container_body_cont1_img_display_cont_div2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
img.sell_container_body_cont1_img_display_cont_div2_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sell_container_body_cont1_img_display_cont_divs_close_icon {
  position: absolute;
  cursor: pointer;
  right: 3%;
  top: 3%;
  background: #0000005e;
  color: #ffffff;
  padding: 5px;
  font-size: 33px !important;
  border-radius: 10px;
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 10px #8080805c;
}
.sell_container_body_cont1_submit_btn {
  width: 100%;
  max-width: 210px;
  margin: auto;
  padding: 15px 0px;
  border-radius: 11px;
  border: none;
  background: #229e54;
  color: #fff;
  font-weight: 600;
}
.acceptDeclineBidButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0em;
}
.acceptDeclineBidButtons_accept {
  padding: 10px;
  width: 150px;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  color: #fff;
  background: #229e54;
}

.acceptDeclineBidButtons_decline {
  padding: 10px;
  width: 150px;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  color: #fff;
  background: #b64141;
}
@media screen and (max-width: 991px) {
  .sell_container_header {
    font-size: 34px;
    color: #fff;
    font-weight: 600;
  }
  .sell_container_body_cont1 {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
  }
  .sell_container_body_cont1_img_display_cont {
    width: 100%;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .sell_container_body_cont1_img_display_cont_1 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1em;
  }
  .sell_container_body_cont1_img_display_cont_divs {
    height: 14em;
  }
}
